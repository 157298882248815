import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgCoffeeCup from "../img/E_kawa_mielona_19/mielona czarka@0,5x.png";
import ImgCoffeeCupBig from "../img/D_kawa_ziarnista_18/ziarnista_czarka.png";

import ImgCoffeeFine from "../img/E_kawa_mielona_19/Iko_drobno_mielona.png";
import ImgCoffeeMed from "../img/E_kawa_mielona_19/iko_średnio_mielona.png";
import ImgCoffeeCoa from "../img/E_kawa_mielona_19/iko_grubo_mielona.png";

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import ImgInstantCoffeeBack from "../img/D_kawa_ziarnista_18/iko_rozpuszczalna.png";
import ImgGroundCoffeeBack from "../img/E_kawa_mielona_19/iko_ziarnista.png";

export default function Header(props) {



    return (
            <React.Fragment>
                <section id="coffeebeans" class="coffeebeans firstSection">
                    <h1>{i18next.t('GroundCoffeeH1')}</h1>
                    <p>{i18next.t('GroundCoffeeDesc')}</p>
                    <div class="items">

                        <div class="item">
                            <div> <img loading="lazy" src={ImgCoffeeFine} alt="Instanta Fine Ground Coffee" /></div>
                            <div>
                                <h2>{i18next.t('GroundCoffeeFine')}</h2>
                                <p>{i18next.t('GroundCoffeeFineDsc')}</p>

                            </div>
                        </div>
                        <div class="item">
                            <div><img loading="lazy" src={ImgCoffeeMed} alt="Instanta Medium Ground Coffee" /></div>
                            <div>
                                <h2>{i18next.t('GroundCoffeeMedium')}</h2>
                                <p>{i18next.t('GroundCoffeeMediumDsc')}</p>
                            </div>
                        </div>
                        <div class="item">
                            <div><img loading="lazy" src={ImgCoffeeCoa} alt="Instanta Coarse GroundB Coffee" /></div>
                            <div>
                                <h2>{i18next.t('GroundCoffeeCoarse')}</h2>
                                <p>{i18next.t('GroundCoffeeCoarseDsc')}</p>
                            </div>
                        </div>


                    </div>

                    <div class="links">
                        <div class="link">
                            <Link to={'/OurPackaging/Packaging'}>
                            <p>{i18next.t('CoffeeBeansLink1')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/Collaboration/'}>
                            <p>{i18next.t('CoffeeBeansLink2')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/Quality/'}>
                            <p>{i18next.t('CoffeeBeansLink3')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/OurPackaging/Design'}>
                            <p>{i18next.t('CoffeeBeansLink4')}</p>
                            </Link></div>
                    </div>

                    <div class="cup"><img loading="lazy" src={ImgCoffeeCup} alt="Instanta Coffee Cup" />
                    </div>
                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurCoffees/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                        <div>
                            <Link to={'/OurCoffees/InstantCoffee'}>
                            <img loading="lazy" src={ImgInstantCoffeeBack} alt="Instant Coffee Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu2')}</p>
                            </Link>
                        </div>   <div>
                            <Link to={'/OurCoffees/CoffeeBeans'}>
                            <img loading="lazy" src={ImgGroundCoffeeBack} alt="Instanta Coffee Beans Icon" />
                            <p>{i18next.t('GroundCoffeeMiniMenu3')}</p>
                            </Link>
                        </div>
                    </aside>
                </section>


            </React.Fragment>

            )


}